(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-winners/assets/javascripts/poy-winners.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-winners/assets/javascripts/poy-winners.js');
"use strict";


const {
  PokerPaper,
  PoyTable
} = svs.poker_react.components;
const PoyWinners = () => {
  const {
    winList,
    listHeader,
    numberOfPlayers
  } = svs.poker_react.data.poyWinConfig;
  const playerList = winList.map((player, index) => ({
    value: player.win,
    name: player.name,
    rankingPos: index + 1
  }));
  return React.createElement("div", {
    className: "poy-winners-container f-content"
  }, React.createElement("h2", null, listHeader ? listHeader : 'Vinnare'), React.createElement(PokerPaper, null, React.createElement(PoyTable, {
    isCurrency: true,
    numberOfPlayers: numberOfPlayers,
    playerList: playerList
  })));
};
setGlobal('svs.poker_react.components.PoyWinners', PoyWinners);

 })(window);